<template>
  <base-section id="pro-features" class="text-center">
    <span v-intersect="handleIntersect"></span>
    <base-section-heading title="Contact Us"> </base-section-heading>

    <v-container class="contact-container">
      <address class="contact-info">
        <h3 class="text-uppercase text-subtitle-1 font-weight-bold">Email</h3>
        <p><a href="mailto:hi@incognit.cloud">hi@incognit.cloud</a></p>
        <h3 class="text-uppercase text-subtitle-1 font-weight-bold">Address</h3>
        <p>
          Centro de Negócios e Serviços do Fundão. Praça Amália Rodrigues,
          6230-350 Fundão. Portugal
        </p>
      </address>
      <div class="map" style="height: 350px">
        <l-map
          style="height: 80%; width: 100%"
          :zoom="zoom"
          :center="center"
          @update:zoom="zoomUpdated"
          @update:center="centerUpdated"
          @update:bounds="boundsUpdated"
        >
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker :lat-lng="markerLatLng"></l-marker>
        </l-map>
      </div>        
    </v-container>
  </base-section>
</template>

<script>
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import { EventBus } from "../../event-bus";
export default {
  name: "SectionProChart",
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 8,
      center: [40.137171, -7.50281],
      markerLatLng: [40.13712, -7.503208],
      bounds: {
        _southWest: { lat: 40.13597203544967, lng: -7.5066876411438 },
        _northEast: { lat: 40.13826866190388, lng: -7.497289180755616 },
      },
      coordinates: {
        lat: 0,
        lng: 0,
      },
      features: [],
    };
  },
  methods: {
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    handleIntersect(entries, observer) {
      if (entries[0].isIntersecting) {
        EventBus.$emit("changeTab", "#pro-features");
      }
    },
  },
  created() {
    this.$getLocation({})
      .then((coordinates) => {
        this.coordinates = coordinates;
      })
      .catch((error) => console.log(error));
  },
};
</script>
